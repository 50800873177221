"use client";
import React, { Component, ErrorInfo, ReactNode } from "react";
import * as Sentry from "@sentry/nextjs";
import { Alert } from "@/design-system/components/Alert";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  isClearing: boolean;
  reloadAttempted: boolean;
}

const RELOAD_ATTEMPT_KEY = "chunkErrorReloadAttempt";

const getReloadAttempted = (): boolean => {
  try {
    return Boolean(window?.sessionStorage?.getItem(RELOAD_ATTEMPT_KEY));
  } catch {
    return false;
  }
};

const setReloadAttempted = (): void => {
  try {
    window?.sessionStorage?.setItem(RELOAD_ATTEMPT_KEY, "true");
  } catch {
    // Do nothing
  }
};

class AsyncChunkErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const hasAttempted =
      typeof window !== "undefined" ? getReloadAttempted() : false;
    this.state = {
      hasError: false,
      isClearing: false,
      reloadAttempted: hasAttempted,
    };
  }

  componentDidMount() {
    window.addEventListener("error", this.handleChunkError);
    window.addEventListener("unhandledrejection", this.handleChunkError);
  }

  componentWillUnmount() {
    window.removeEventListener("error", this.handleChunkError);
    window.removeEventListener("unhandledrejection", this.handleChunkError);
  }

  handleChunkError = (event: Event | PromiseRejectionEvent) => {
    if (event instanceof ErrorEvent && event.error?.name === "ChunkLoadError") {
      event.preventDefault();
      this.setState({ hasError: true });
      if (!getReloadAttempted()) {
        void this.clearCache();
      }
    } else if (
      event instanceof PromiseRejectionEvent &&
      event.reason?.name === "ChunkLoadError"
    ) {
      event.preventDefault();
      this.setState({ hasError: true });
      if (!getReloadAttempted()) {
        void this.clearCache();
      }
    }
  };

  static getDerivedStateFromError(error: Error): State | null {
    if (error.name === "ChunkLoadError") {
      const hasAttempted =
        typeof window !== "undefined" ? getReloadAttempted() : false;
      return {
        hasError: true,
        isClearing: false,
        reloadAttempted: hasAttempted,
      };
    }
    throw error;
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    Sentry.captureException(error, { extra: { errorInfo } });
  }

  clearCache = async (): Promise<void> => {
    this.setState({ isClearing: true });

    try {
      if ("caches" in window) {
        const cacheKeys = await caches.keys();
        await Promise.all(
          cacheKeys.map(async (key) => {
            const cache = await caches.open(key);
            const requests = await cache.keys();
            const nextStaticRequests = requests.filter((request) =>
              request.url.includes("/_next/static/"),
            );

            await Promise.all(
              nextStaticRequests.map((request) => cache.delete(request)),
            );
          }),
        );
      }

      if (process.env.NEXT_PUBLIC_APP_ENV !== "production") {
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }

      setReloadAttempted();
      window.location.reload();
    } catch (error) {
      Sentry.captureException(error);
      this.setState({ isClearing: false });
    }
  };

  render(): ReactNode {
    if (this.state.hasError) {
      if (this.state.isClearing) {
        return (
          <Alert
            title="Loading Error"
            message="Clearing cached files and reloading the page..."
          />
        );
      }
      return (
        <Alert
          title="Loading Error"
          message={
            this.state.reloadAttempted
              ? "Still unable to load required files. You can try refreshing the page with Ctrl + R (on Windows) or Cmd + Shift + R (on Mac) for a fresh start. This issue has been automatically reported to our developers."
              : "There was an error loading some required files. Attempting to fix the issue automatically..."
          }
        />
      );
    }

    return this.props.children;
  }
}

export default AsyncChunkErrorBoundary;
